// Bespoke Robbo

.homeFeatures .homeFeature .homeFeatureDetailsWrapper {
  align-items: center;

  h2,
  p {
    width: 100%;
    max-width: 512px;
  }
}

@media (min-width: map-get($breakpoints, sm) + 1) {
  .menuMainAlt {
    color: #fff;
  }
}

.menuMainAlt {
  border: none;
  margin-left: 0;
  &:hover, &.active{
    background: transparent;
  }
}

.menuMain .menuMainAlt {
  background-color: rgba(#000,.1);
  color: $nav-top-level-item-colour;
}

.homepage-quickgiving--container {
  flex-direction: column;
}

.homepage-quickgiving--copy {
  margin: 0 0 1rem;
}

.homeFeed:nth-child(even) {
  background-color: $grey-lightest;
}

.Footer-Legal {
  text-align: center;
}

// Quick Giving Panel - Donation selected
.quickGivingPanel .formQuestion.donationAmount.donationSelected {
  box-shadow: inset 0px 0px 0px 2px $donate-colour;
}

.homeFeatures .homeFeature {
  transition: $transition-duration-slow all $transition-curve !important;
}

// Bespoke Poppy Scotland

.pageHeader .headerContent > *:not(.mainLogo){
  margin-top:0;
  .cta-button {
    margin: 0 0 0 12px;
  }
}

@media (min-width: map-get($breakpoints, lg)) {

  .menuMain .topLevel{
    padding-right: 75px;
  }

}

  @media (min-width: map-get($breakpoints, lg)) {

  .pageHeader .headerContent {
    margin-top: 1rem;
  }

}

@media (min-width: map-get($breakpoints, sm) + 1) {

  body:not(.subsite).page-has-banner .pageHeader{
    min-height:200px;
  }

}

.button, .cta-button, .readMore, .addToCalender {
  &:hover{
    background: white!important;
    color: $brand-primary!important;
    border-color: $brand-primary!important;
  }
  &.donate:hover{
    background: white!important;
  }
}

.pageFooter { // Simplified footer
  display: block;
  padding: 3em 0;
  ul.socialIcons{
    margin-bottom:20px;
    display: none;
  }
  .Footercredits{
    text-align: center;
  }
  ul.footerLinks li{
    display: inline-block;
    margin: 0 20px 20px 0;
  }
}

// DEL-57
#terms-and-conditions-section {
  width: 100%;
}

.post .appealActionsWrapper,
.postComments #uploadImage.dropzone {
  background-color: white !important;
}

// QA
body.page-no-banner {
  .pageHeader .headerContent {
    margin-top: 0;
    padding-top: 2rem;
  }
  .mainLogo {
    top: 0.9rem;
  }
  #pageHeader {
    height: 8rem;
  }
}