//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 12
  )
}

// Choose your card style for home feature cards
.homeFeatures .homeFeature {
  @include card (
    $card-gap-width: 0,
    $card-details-background-colour: $grey-lightest,
    $card-hover-details-background-colour: $grey-lightest
  );

  &:nth-child(odd) {
    @include card-side-by-side (
      $card-side-by-side-image-position: right,
      $card-side-by-side-vertical-text-position: center
    );
  }

  &:nth-child(even) {
    @include card-side-by-side (
      $card-side-by-side-vertical-text-position: center
    );
  }
}


//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 1
);

.feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
}

// Choose your card style for home feed cards
.feedItem {
  @include card;
  @include card-basic;
}


//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card;
  @include card-basic;
}


//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above, you can remove the ':not(.listedProduct)' selector above and squash this together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}