// //
// // Environment
// //

// // Fresco will give you 'warnings' in your terminal every time you compile a single client using the 'gulp --client clientname' command
// // If you really want to turn these off, you can add '$warn: false;' to your client _variables.scss
// // However, these warnings do usually offer useful and important pointers, so just be careful!
// $warn: false !default;


// // Config values
$platform-name: "poppyscotland";
//$assets-path: "../assets/" !default;

// Starter variables below:

$brand-primary: #d52b1e;
$brand-secondary: #1e1e1e;
$donate-colour: $brand-primary;

$grey-dark: #1e1e1e;
$grey-lightest: #f0f0f0;

$border-colour: #eee;
$border-radius: 0;

$container-max-width: 1480px;
$gap-width: 40px;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
) !default;

// Spacers
$spacer: 1rem !default;
$spacer-y: $spacer !default;
$spacer-x: $spacer !default;

$font-family-base: "Poppins", sans-serif !default;
$body-colour: #16161D;
$font-size-base: 1rem;
$font-size-large: 1.25em !default;
$headings-font-family: $font-family-base;
$headings-colour: $body-colour;
$headings-text-transform: none;
$headings-font-weight: 700;
$headings-letter-spacing: initial;

$btn-font-family: $font-family-base;
$btn-text-transform: none;
$btn-font-weight: 700;
$btn-font-size: 0.875em;
$btn-border-radius: 2em 2em 2em 0;
$btn-border-width: 2px;
$btn-border-colour: $brand-primary;
$btn-padding-x: 1.5em;
$btn-padding-y: .55em;
$btn-font-size: $font-size-large;
$btn-transition-duration: .3s;

$menu-admin-enabled: false;

$header-absolute: true;
$header-absolute-background: linear-gradient(
  to bottom,
  rgba(#000, 0.8),
  rgba(#000, 0.65),
  80%,
  rgba(#000, 0)
);

$header-background-colour: black; // Only on mobile devices when absolute header is removed
$header-content-max-width: $container-max-width; 
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;
$header-content-padding-top: $spacer;
$header-content-padding-bottom: 2rem;
$header-content-padding-left: 20px;
$header-content-padding-right: 20px;

$logo: "logo.png";
$logo-retina: $logo;
$logo-width: 188px;
$logo-height: 100px;
$logo-absolute: true; // true if we want the logo to vertically intersect with the menu


$no-banner-header-background-colour: $grey-dark;
$no-banner-logo: "logo.png";
$no-banner-logo-retina: $no-banner-logo;

$cta-btn-background-colour: transparent;
$cta-btn-colour: white;
$cta-btn-gap: 1rem;
$cta-donate-on-appeal: false; // Do you want to show the main cta donate button when on appeal post/donate form?

$header-search-enabled: false;

$page-title-colour: $headings-colour;
$header-social-icons-breakpoint: 999999999px;

// Footer specific social icons
$social-icons-footer-background-colour: transparent; // A colour, transparent or 'brand'
$social-icons-footer-colour: white; // A colour, or 'brand'
$social-icons-footer-hover-colour: white; // A colour, or 'brand'

$nav-type: normal;
$nav-background-colour: transparent;
$nav-breakpoint: map-get($breakpoints, xl) !default;

$nav-top-level-item-colour: white;
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-chevrons-enabled: false;
$nav-top-level-item-font-weight: 700;
$nav-top-level-item-background-colour: transparent;
$nav-top-level-item-font-size: $font-size-large;

$nav-submenu-background-colour: white;
$nav-submenu-item-colour: black;
$nav-submenu-item-font-weight: 700;
$nav-submenu-item-hover-colour: $brand-primary;
$nav-submenu-item-hover-background-colour: transparent;

$nav-normal-max-width: $container-max-width !default; // $container-max-width
$nav-normal-align-items: center; // left, center, right
$nav-normal-margin-top: -92px;

$burger-btn-background-colour: transparent;
$burger-btn-text-colour: #fff; // TODO this aint working
$burger-btn-text-transform: uppercase;

$header-search-breakpoint: 10000px; // We only want the search bar in the nav
$nav-search-input-margin-right: 0px;
$nav-search-input-padding: 10px;
$nav-search-input-background-colour: rgba(#fff, 0.5);
$nav-search-button-background-colour: $nav-search-input-background-colour;
$nav-search-button-icon-colour: $nav-top-level-item-colour;

$carousel-max-width: 100%;
$carousel-image-overlay: rgba(#222, 0.6);
$carousel-details-position-y: bottom;
$carousel-details-position-x: left;
$carousel-details-max-width: 600px;
$carousel-details-background-colour: transparent;
$carousel-details-text-align: left;
$carousel-heading-colour: #fff;
$carousel-heading-font-size: 3.5rem;
$carousel-summary-enabled: true;
$carousel-summary-colour: #fff;
$carousel-summary-font-size: 1.25rem;
$carousel-read-more-enabled: false;
$carousel-read-more-font-size: 1rem;
$carousel-contents-max-width: $container-max-width; // How wide do you want .carouselDetailWrapper?
$carousel-details-margin-y: 80px;


$shop-enabled: true;
$associated-latest-enabled: true;
$associated-related-enabled: true;
$associated-most-read-enabled: true;

$blog-details-enabled: true;
$blog-details-profile-pic-enabled: false;

$home-intro-margin-top: 0;
$home-intro-padding-y: 6rem;
$home-intro-max-width: 1000px;
$home-intro-background-colour: transparent;
$home-intro-colour: $body-colour;
$home-intro-font-size: 2rem;
$home-intro-line-height: 1.8em;

$home-features-max-width: 100%;
$home-features-padding-top: 0;
$home-features-padding-bottom: 0;

$impact-stats-background-colour: $brand-primary;
$impact-stats-padding-y: 4rem;
$impact-stats-margin-bottom: 0;
$impact-stats-heading-font-size: 2.5rem;
$impact-stats-heading-margin-bottom: 1rem;
$impact-stats-figure-colour: $brand-secondary;
$impact-stats-figure-font-size: 5rem;
$impact-stats-summary-font-size: 1rem;

$quick-giving-donation-amount-figure-font-size: 1.375rem;
$quick-giving-donation-amount-background-colour: #fff;
$quick-giving-donation-amount-selected-background-colour: $brand-primary;

$home-quick-giving-padding-y: 4rem;
$home-quick-giving-heading-font-size: 2.5rem;
$home-quick-giving-donation-amount-background-colour: #fff;
$home-quick-giving-donation-amount-selected-background-colour: #fff;
$home-quick-giving-donation-amount-selected-figure-colour: $donate-colour;
$home-quick-giving-donate-btn-background-colour: $donate-colour;

$feeds-title-font-size: 2.5rem;
$feeds-title-text-align: center;

$card-border: 0;
$card-details-padding: 1.5rem;
$card-details-background-colour: transparent;
$card-heading-colour: $headings-colour;
$card-heading-font-size: 1.375rem;
$card-footer-background-colour: transparent;
$card-footer-padding: 0 $card-details-padding;

$footer-boxes-count: 3;
$footer-prefab: 4;
$footer-padding-y: 0;
$footer-background-colour: $grey-dark;
$footer-link-colour: white;
$footer-font-size: 0.875rem;
$footer-headings-font-size: 1.375rem;

$newsletter-background-colour: $brand-primary;
$newsletter-heading-font-size: 2.25rem;
$newsletter-button-background-colour: text-contrast($brand-secondary);

$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts